import React, { useContext, useEffect, useState } from "react"
import {useLocation, useSearchParams} from 'react-router-dom';
import { CustomTabs } from "../Shipping/style"
import { Container } from "../GeneralSettings/style"
import Header from "components/CommonComponents/Header"
import ManageOtherPayment from "./Others"
import { useQuery, useQueryClient } from "react-query"

import { getGeneralDetails } from "../GeneralSettings/script"
import ManageUPIPaymentNew from "./NewUPI"
import {GetAuthToken} from 'globalQuries'


import { verifyCashfree } from "./script"
import { resourceUsage } from "process";
import axios from "axios";
import { notification } from "antd";

const { TabPane } = CustomTabs;

const ManagePayment = () => {
    const [tabKey, setTabKey] = useState<string>("1")
    const { data, isLoading, isSuccess } = useQuery('generalSettings', getGeneralDetails);
    const onTabChange = (key: string) => {setTabKey(key)}
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading]= useState(false)
    const [success , setSuccess] =useState(false);
    const [cashfreeSuccess, setCashfreeSuccess] = useState(false);
    const [cashfreeLoading, setCashfreeLoading] = useState(false);
    const queryClient = useQueryClient();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const cashfreeCode = queryParams.get('code')
    const cashfreeMerchatId = queryParams.get('merchant_id')
    const sellerId = data?._id;


    useEffect(() => {
        const auth = GetAuthToken();
       
        const isCashfree = cashfreeMerchatId?.split("_")?.[0].includes('CF');

        const verifyCallCashfree =  async () => {
        const url = `${process.env.REACT_APP_PAYMENT}/api/ve1/payment-aggregator/seller/config/auth/cashFree-auth-callback`;
        try{
        const response = await axios.get(url, {
              params: {  
                sellerId:sellerId,
                code: cashfreeCode, 
                gateway:"cashfree", 
                merchantId: cashfreeMerchatId
              },
              headers: {'X-Auth':auth} 
            },
        )

        if(response?.data?.error){
            notification.error({ message: 'Something went wrong' });
            setCashfreeLoading(false)
            searchParams.delete('code')
            searchParams.delete('merchant_id')
            setSearchParams(searchParams)
        }else { 
            setCashfreeSuccess(true)
            setCashfreeLoading(false)
            notification.success({message : 'Authorization successful'})
            searchParams.delete('state')
            searchParams.delete('merchant_id')
            setSearchParams(searchParams)
            queryClient.invalidateQueries(`userDetails`)
        }
        
        }catch(error){
            notification.error({ message: 'Something went wrong' });
            setCashfreeLoading(false)
            searchParams.delete('code')
            searchParams.delete('merchant_id')
            setSearchParams(searchParams)
        }
    }   
        if(isCashfree && cashfreeCode && sellerId){
            verifyCallCashfree();
        }

    }, [cashfreeCode, sellerId])







    return (
        <Container>
        {/*// @ts-ignore */}
        <Header
          title="Payment"
        />
        <CustomTabs defaultActiveKey={tabKey} onChange={onTabChange}>
            <TabPane tab="FASTRR CHECKOUT" key="1">
                {/* <ManageUPIPayment data={data} isLoading={isLoading}/> */}
                <ManageUPIPaymentNew
                    loading = {loading} 
                    setLoading= {setLoading}
                    success = {success}  
                    setSuccess = {setSuccess}
                    cashfreeLoading = {cashfreeLoading}
                    setCashfreeLoading = {setCashfreeLoading}
                    cashfreeSuccess = {cashfreeSuccess}
                    setCashfreeSuccess = {setCashfreeSuccess}
                        
                />
            </TabPane>
            <TabPane tab="OTHERS" key="2">
                <ManageOtherPayment data={data} isLoading={isLoading} />
            </TabPane>
        </CustomTabs>
        </Container>
    )
}

export default ManagePayment