import React, { useState } from 'react';
import { CrossIcon } from "images/icons"
import { CustomPickrrButton, NormalText, PrimaryText } from 'pages/Orders/OrdersTab/Refund/styled';
import { FlexContainer } from 'components/UIElements';
import axiosInstance from 'HTTP/Http';
import { useQueryClient } from 'react-query';

const CreatePendingOrderModal = ({ setShowCreateOrderModal, rowDetails }: any) => {

    const [createOrderResponse, setCreateOrderResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();


    const createPendingOrder = async (txnId: any) => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/retry-pending-order/${txnId}`);
            setCreateOrderResponse(response.data);
        } catch (error) {
            console.error("Error creating order:", error);
        } finally {
            setLoading(false);
            setShowCreateOrderModal(false);
            queryClient.invalidateQueries('pendingOrdersData');
        }

    }

    function createOrderHandler() {
        if (rowDetails?.txnId) {
            createPendingOrder(rowDetails?.txnId);
        }
    }


    const Confirm = (
        <>
            <PrimaryText>Are you sure you want to create a new order?</PrimaryText>
            <NormalText style={{marginTop: '10px'}}>Changes will be reflected within few minutes</NormalText>

            <div className='mt-3'>
                <FlexContainer style={{ gap: 12, marginTop: 8 }}>
                    <CustomPickrrButton
                        color="blue"
                        background-color="blue"
                        onClick={createOrderHandler}
                        disabled={loading}
                    >
                        Confirm
                    </CustomPickrrButton>
                    <CustomPickrrButton
                        onClick={() => { setShowCreateOrderModal(false) }}
                        color="transparent"
                        background-color="transparent"
                    >
                        Cancel
                    </CustomPickrrButton>
                </FlexContainer>
            </div>
        </>
    )

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="fixed inset-0 bg-black opacity-50"
                onClick={() => { setShowCreateOrderModal(false) }}
            />
            <div className="flex flex-col z-50 bg-refundWhiteBg rounded-lg  p-4 h-fit-content w-[550px]">
                <div
                    className='cursor-pointer h-5 mb-3 flex justify-end'
                    onClick={() => { setShowCreateOrderModal(false) }}
                >
                    <CrossIcon />
                </div>

                <div className='flex flex-col relative items-center mb-3'>
                    {Confirm}
                </div>
            </div>



        </div>


    )
}

export default CreatePendingOrderModal