import { notification } from 'antd';
import axiosInstance from '../../../HTTP/Http';
import {GetAuthToken} from 'globalQuries'
import axios from 'axios';

export const FetchPaymentConfig = async ({queryKey}) => {
    const [unusedArgument, {sellerId}] = queryKey;
    try {
      const response = await axiosInstance.get(
        // `https://payment-aggregator-checkout-dev.pickrr.com/api/ve1/payment-aggregator/seller/config/v3/${sellerId}?api_version=v3`,
        `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/payment-config/${sellerId}?api_version=v3`
      );
      return response.data;
    } catch (error) {
      notification.error({
        message: error.message,
      });
    }
  };

export const UpdatePaymentGateway = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/upsert-seller-payment-config`,
      payload
    )
    return response?.data
  } catch (error) {
    notification.error({
      message: error.message,
    });
  }
}

export const verifyCashfree = async (payload) => {
  
  const auth = GetAuthToken();
  try {
    
    const response = await axios.get(`${process.env.REACT_APP_PAYMENT}/api/ve1/payment-aggregator/seller/config/auth/cashFree-auth-callback`, 
      {
        params: {  
          sellerId:payload?.sellerId,
          code: payload?.code, 
          gateway:"cashfree", 
          merchantId: payload?.merchantId
        },
        headers: {'X-Auth':auth} 
      },
  
    )

    return response?.data

  }catch(error){
    return error?.data
    // notification.error({
    //   message: error.message
    // })
  }
}

// const response = await axios.get(`${process.env.REACT_APP_PAYMENT}/api/ve1/payment-aggregator/seller/config/auth/cashFree-auth-callback`, 
//   {
//     params: {
//       sellerId:params?.sellerId,
//       code: params?.code, 
//       gateway:params?.gateway
//     }, 
//     headers: {'X-Auth':auth} 
//   }
// )



// const response = await axios.get(
//   `${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/report?key=customer&type=CUSTOMER`,
//   {
//     params: {
//       from: fromDate,
//       to: toDate,
//       value: keys,
//     },
//     headers: {
//       "X-Auth": auth,
//     },
//   }
// );