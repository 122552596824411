import moment from "moment";
import { idText } from "typescript";
import { notification } from "antd";
import Configuration from "./Configuration/Index";


export const showErrorMessage = (message: string) => {
  notification.error({ message: message })
}

const items = (item: any, type?: string) => {
  let variant: any = item?.map((value: any) => {
    return {
      id: value?.id,
      name: value?.name,
      price: value?.price,
      ...(!(value?.variants?.length === value?.selectedItems?.length) && {
        variants: value?.selectedItems ? value?.selectedItems : value.variants,
      }),
      checked: value?.selectedItems?.length ? value?.variants?.length === value?.selectedItems?.length : value.checked,
      variantCount: value?.selectedItems?.length ? value?.variants?.length : value.variantCount,
      ...(type === 'bundle' && { quantity: value.quantity ? value.quantity : 1 }),
    };
  });
  return variant;
};

const itemsforupselling= (item: any, type?: string) => {
  let variant: any = item?.map((value: any) => {
    return {
      id: value?.id,
      name: value?.name,
      price: value?.price,
      variants: value?.selectedItems ? value?.selectedItems : value.variants,
      checked: value?.selectedItems?.length ? value?.variants?.length === value?.selectedItems?.length : value.checked,
      variantCount: value?.selectedItems?.length ? value?.variants?.length : value.variantCount,
    };
  });
  return variant;
};

const itemsforFreebie=(item:any,type?:string)=>{
  let variant: any = item?.map((value: any) => {
    console.log("This is current value",value)
    return {
      id: value?.id,
      name: value?.name,
      price: value?.price,
      quantity:value?.quantity,
      variants: value?.selectedItems ? value?.selectedItems : value.variants,
      checked: value?.selectedItems?.length ? value?.variants?.length === value?.selectedItems?.length : value.checked,
      variantCount: value?.selectedItems?.length ? value?.variants?.length : value.variantCount,
    };
  });
  return variant;

}




// const itemsforfreebie=(item:any,type?:string)=>{
//   let variant:any=item?.map((value:any)=>{
//     return {
//       id:value?.id,
//       name:value?.name,
//       price:value?.price,
//       variants:value?.selectedItems ? value?.selectedItems : value.variants,
//       checked:value?.selectedItems?.length? value?.variants?.length === value?.selectedItems?.length : value.checked,
//       variantCount: value?.selectedItems?.length ? value?.variants?.length : value.variantCount,
//     }
//   })
//   return variant;
// }

export const productCriteriaData = (
  values?: any,
  productList?: any,
  type?: any,
  tagsString?:any
) => {
  // let productFilter;
  if (values?.excludeProduct || productList?.length || values?.productType) {
    const productFilter = {
      ...(values?.excludeProduct && { excludeProduct: values?.excludeProduct }),
      ...((type==="product"||type==="collection") && productList?.length > 0 &&{
        searchList: type === "product" ? items(productList) : [...productList],
      }),
      ...(type==="tags" && tagsString?.length>0 && {
        tagsString:tagsString
      }),

      ...(values?.productType && { productType: values?.productType }),
    };
    return { productFilter };
  }
};


const collectBundleItems = (
  productList?: any,
  type?: any,
  discountType?: any
) => {
  // let productFilter;
  if (discountType || productList?.length || type) {
    const productFilter = {
      excludeProduct: false,
      ...(productList?.length > 0 && {
        searchList: type === "product" ? items(productList, discountType) : [...productList],
      }),
      productType: type,
    };
    return { productFilter };
  }
};

export const minimumCartTotalData = (values: any) => {
  const minCartTotal = values?.minCartTotal;
  return { minCartTotal };
};

export const maximumCartTotalData = (values: any) => {
  const maxCartTotal = values?.maxCartTotal;
  return { maxCartTotal };
};


export const customerCriteriaData = (
  values: any,
  selectedPhoneNumbers: any,
  selectedPinCode:any,
  orderCount:number
) => {
  const phoneNumbers = selectedPhoneNumbers?.map((e: any) => {
    return e?.number;
  });
  const pinCode=selectedPinCode?.map((e:any)=>{
    return e?.number;
  })
  let customerFilter:any;
  if (values?.customerDiscount=='specificCustomers'){
    customerFilter = {
    customerType: values?.customerDiscount,
    excludeCustomer:values?.excludeCustomer,
    customerList: phoneNumbers,
  }; }
  else if (values?.customerDiscount=='customer_in_pincode'){
    customerFilter={
      customerType:values?.customerDiscount,
      pincodeList:pinCode
    }
  }
  else if (values?.customerDiscount==='customer_with_x_orders'){
    customerFilter={
      customerType:values?.customerDiscount,
      'orderCount':orderCount

     } 
  }
  else{
    customerFilter={
      customerType:values?.customerDiscount,
      customerList:phoneNumbers
    }
  }


  if (values?.customerDiscount === 'risk') {
    customerFilter = { ...customerFilter, riskType: values?.riskType }
  }
  return { customerFilter };
};
export const usageCriteriaData = (values: any) => {
  if (values?.usageLimit || values?.customerLimit) {
    const discountUsage = {
      usageLimit: values?.usageLimit,
      customerLimit: values?.customerLimit,
    };
    return { discountUsage};
  }
};
export const quantityCriteriaData = (values: any) => {
  console.log("The values are========+>",values)

  if (values?.minQtyProduct || values?.itemLevel) {
    return {
      minQtyProduct: values?.minQtyProduct, 
      itemLevel: values?.minQtyItemLevel};
     }
};

export const bankOfferCriteriaData = (values: any, bankImg:string) => {
  console.log(bankImg, 'checking bank image')


  let paymentMethodFilter:any;
  const cardType = values?.cardType === "both" ? ['debit', 'credit'] : [values?.cardType];


  if (values?.paymentMethod === "card") {
    paymentMethodFilter = {
      paymentMethod : values?.paymentMethod,
      cardConfig : {
        type : [...cardType],
        banks : values?.banksList ? [values?.banksList] : [],
        network : values?.network,
        issuerIdentificationNumber: values?.issuerIdentificationNumber
      },
      imageUrl: bankImg 
        ? bankImg 
        : 'https://pickrr.s3.amazonaws.com:443/2023-11-23T12:12:20.064705_generic-image_05.jpg'
    }
  }else{
    paymentMethodFilter = {
      paymentMethod: values?.paymentMethod,
      issuer: values?.banksList ? [values?.banksList] : [],
      imageUrl: bankImg 
      ? bankImg 
      : 'https://pickrr.s3.amazonaws.com:443/2023-11-23T12:12:20.064705_generic-image_05.jpg'
    }
  }
  
  return { paymentMethodFilter };

};


export const utmCriteriaData = (values: any) => {
    const utmFilter = values?.utmFilter?.filter((utm : any) => {
      if (utm.source || utm.medium || utm.campaign)
        return { source: utm.source, medium: utm.medium, campaign: utm.campaign };
    })
    return {utmFilter} ;
};

export const freebieModeData = (values: any) => {
  if(values.paymentMethodsCriteria) {
    return {
      paymentMethodsCriteria: ["PREPAID"]
    }
  } else {
    return {}
  }
}

const tiered = (value: any, typeOfTiered: string) => {
  const tieredItems =
    value.length &&
    value.map((item: any) => {
      const items = { [typeOfTiered]: item[typeOfTiered], amount: item.amount };
      return items;
    });
  return tieredItems;
};

const makeTime = (startDate: any, startTime: any) => {
  let finalDate;
  const format1 = `YYYY-MM-DD[T]HH:mm:ssZ`;

  // let p = startDate.set({
  //   hour: startTime.get('hour'),
  //   minute: startTime.get('minute'),
  // });
  // return moment(p).format(format1)

  if (startDate && startTime) {
    const time = moment(startTime).format("HH:mm");
    const minutes = moment(time, "HH:mm").get("minutes");
    const hours = moment(time, "HH:mm").get("hours");
    const date = moment(startDate).format("YYYY-MM-DD");
    let newDateMinute = moment(date).add(moment.duration(minutes, "minutes"));
    let newDateHours = moment(newDateMinute).add(moment.duration(hours, "hours"));

    finalDate = moment(newDateHours, format1).format(format1);
  } else if (startDate) {
    finalDate = moment(startDate, format1).format(format1)
  }

  return finalDate;
};

export const discountTypeData = (values: any) => {
  return {
    discountMethod: {
      automaticDiscount:
        (values?.discountMethod === "automaticDiscount" ||  values?.discountMethod === "fomo" )  ? true : false,
      couponCode: values?.couponCode ? values?.couponCode : null,
    },
  };
};

// configurationValue
export const discountConfigData = (
  values: any,
  discountType: string,
  customerBuysItems: any,
  customerGetsItems: any,
  selectTypes: any,
  bundleItem: any,
  discountSummary: any,
  setDiscountSummary: any,
  freebieList:any,
  tieredFlag:any,
  setTieredFlag:any,
) => {
  if (discountType === "bxgy") {
    return {
      discountConfig: {
        discountCond: selectTypes.bxgyType,
        enableLimit: values?.enableLimit,
        maxLimit: values?.maxLimit,
        customerBuys: {
          itemLevel:values?.bxgyItemLevel||false,
          [selectTypes.bxgyType]:
            values?.[`customerBuys${selectTypes.bxgyType}`],
          productType: values?.customerBuysListType,
          searchList:
            values?.customerBuysListType === "product"
              ? items(customerBuysItems)
              : [...customerBuysItems],
        },

        customerGets: {
          quantity: values?.customerGetsquantity,
          productType: values?.customerGetsListType,
          bxgyPercent: values?.bxgyPercent ?? 0,
          bxgyFlat: values?.bxgyFlat ?? 0,
          searchList:
            values?.customerGetsListType === "product"
              ? items(customerGetsItems)
              : [...customerGetsItems],
        },
      },
    };
  } else if (discountType === "aftersell") {
    const temp: any = {}
    if (selectTypes.configType == 'percent') {
      temp['aftersellPercentage'] = values?.aftersellPercentage
      temp['aftersellFlat'] =0

    } else {
      temp['aftersellFlat'] = values?.flat
      temp['aftersellPercentage'] = 0

    }
    return {
      discountConfig: {
        ...temp,
        productType: values?.customerBuysListType,
        productList: itemsforupselling(customerBuysItems),
        aftersellList: itemsforupselling(customerGetsItems)
      }
    }
  }
  else if (discountType=="freebie"){
    const temp:any={}
    if (selectTypes.configType==='percent'){
      temp['discountPercentage']=parseInt(values?.configValue)
      temp['discountFlat'] = 0
      temp['freebieFlat']=0
    }
    else if (selectTypes.configType==='flat'){
      temp['discountFlat']=parseInt(values?.configValue)
      temp['freebieFlat']=0
      temp['discountPercentage']=0
    }
    else{
      temp['freebieFlat']=parseInt(values?.configValue)
      temp['discountPercentage'] = 0
      temp['discountFlat']=0
    }
    return {
      discountConfig:{
        ...temp,
        freebies:itemsforFreebie(freebieList)
      }
    }
  }
   else {
    return {
      discountConfig: {
        ...(selectTypes.offerBundleDiscountType === "offerDiscountFlat" ? { type: "flatValue" } : { type: values?.configDiscountType }),
        ...(discountType !== "tiered" && { value: values?.configValue }),
        ...(discountType === "bankOffer" && {pgDiscountId: values?.pgDiscountId }),
        ...(discountType === "bundle" &&

          collectBundleItems(bundleItem, selectTypes?.bundleType, discountType)
          // { searchList: items(bundleItem, 'bundle') }
        ),
        ...(discountType === "bundle" && {quantity:values?.bundleQuantity,dynamicBundle:values?.dynamicBundle}),
        ...(discountType === "prdAmt" && {
          enableLimit: values?.enableLimit,
          maxLimit: values?.maxLimit
        }),
        ...(discountType === "tiered" && {
          tiers: values.tiers.map((tier:any)=>{return {tierValue:tier?.tierValue,offValue:tier?.offValue,itemLevel:tieredFlag} }),
          discountCond: selectTypes.tieredType,
        }),
      },
    };
  }
};

export const discountFunctionalityData = (values: any) => {
  console.log(values, "checking values");
  return {
    discountFunctionality: {
      visibility: {
        title: values?.title ?? null,
        description: values?.description ?? null,
        tnc: values?.tnc ?? null,
        active: values.VisibilityActive ?? false,
        showApplicable:values?.showApplicable ?? false,
      },
      combination: {
        values: values.combination ?? null,
        active: values.CombinationActive ?? false,
      },
      capping: {
        value: values.caping ?? null,
        active: values.CapingActive ?? false,
      },
      discountLink: {
        value: values.discountLink ?? null,
        active: values.DiscountlinkActive ?? false,
      },
      bulkGenerate: {
        affix: values?.bulkType ?? null,
        affixValue: values?.affixValue ?? null,
        noOfCodes: values.noOfCodes ? values.noOfCodes : null,
        active: values.BulkGenerateActive ?? false,
        email: values.bulkEmail
      },
      paymentModes: values.PaymentModesActive ? values.paymentModes : []
    },
  };
};

export const discountValidityData = (value: any) => {
  const startTime = makeTime(value.startDate, value.startTime);
  const endTime = makeTime(value.endDate, value.endTime);

  return {
    discountValidity: {
      startTime: startTime,
      ...(endTime && { endTime: endTime })
    },
  };

};
const getConfigValue=(data:any)=>{
  if (data?.discountConfig?.value){
    return data?.discountConfig?.value
  }
  else if (data?.discountConfig?.discountFlat){
    return data?.discountConfig?.discountFlat
  }
  else if (data?.discountConfig?.discountPercentage){
    return data?.discountConfig?.discountPercentage
  }
  return data?.discountConfig?.freebieFlat
  
}

export const setFormValues = (data: any, form: any, couponsEditData: any) => {
  if (data) {
    form.setFieldsValue({
      // discountType
      type: data?.discountType,
      discountMethod: 
      couponsEditData?.type === "fomo"
      ? "fomo"
      : data?.discountMethod?.automaticDiscount
        ? "automaticDiscount"
        : "couponCode",
      couponCode: data?.discountMethod?.couponCode,

      // config
      configDiscountType: data?.discountConfig?.type,
      // configValue: data?.discountConfig?.value, 
      configValue:getConfigValue(data),
      // ||data?.discountConfig?.freebiePercentage,
      // limitValue:data?.discountConfig?.limitValue,
      pgDiscountId: data?.discountConfig?.pgDiscountId,
      bundleConfigType: data?.discountConfig?.value,
      tiers: data?.discountConfig?.tiers,
      typeOfTiered: data?.discountConfig?.discountCond,
      bundleProductType: data?.discountConfig?.productFilter?.productType,
      offerBundleDiscountType: data?.discountConfig?.type === "flatValue" ? "offerDiscountFlat" : 'offerDiscount',

      //discount config
      [`customerBuys${data?.discountConfig?.discountCond}`]:
        data?.discountConfig?.customerBuys?.[
        data?.discountConfig?.discountCond
        ],
      customerBuysListType: data?.discountConfig?.customerBuys?.productType,
      bxgyItemLevel:data?.discountConfig?.customerBuys?.itemLevel,
      customerGetsquantity: data?.discountConfig?.customerGets?.quantity,
      customerGetsListType: data?.discountConfig?.customerGets?.productType,
      bxgyGetDiscountValue: data?.discountConfig?.customerGets?.bxgyPercent
        ? 'bxgyPercent'
        : 'bxgyFlat',
      bxgyFlat: data?.discountConfig?.customerGets?.bxgyFlat,
      bxgyPercent: data?.discountConfig?.customerGets?.bxgyPercent,
      enableLimit: data?.discountConfig?.enableLimit,
      maxLimit: data?.discountConfig?.maxLimit,
      bundleQuantity: data?.discountConfig?.quantity,
      dynamicBundle:data?.discountConfig?.dynamicBundle,
      
      //criteriaFunctionality
      excludeProduct:
        data?.discountCriteria?.productFilter?.excludeProduct ?? false,
      productType: data?.discountCriteria?.productFilter?.productType,
      excludeCustomer:data?.discountCriteria?.customerFilter?.excludeCustomer ?? false,
      minCartTotal: data?.discountCriteria?.minCartTotal,
      maxCartTotal: data?.discountCriteria?.maxCartTotal,
      customerDiscount: data?.discountCriteria?.customerFilter?.customerType,
      usageLimit: data?.discountCriteria?.discountUsage?.usageLimit,
      customerLimit: data?.discountCriteria?.discountUsage?.customerLimit,
      minQtyProduct:data?.discountCriteria?.minQtyProduct,
      minQtyItemLevel:data?.discountCriteria?.itemLevel,
      
      paymentMethod: data?.discountCriteria?.paymentMethodFilter?.paymentMethod,
      cardType: data?.discountCriteria?.paymentMethodFilter?.cardConfig?.type?.length > 1 
      ?  "both"
      : data?.discountCriteria?.paymentMethodFilter?.cardConfig?.type?.[0],
      banksList: data?.discountCriteria?.paymentMethodFilter?.cardConfig?.banks?.[0] 
      ? data?.discountCriteria?.paymentMethodFilter?.cardConfig?.banks?.[0] 
      : data?.discountCriteria?.paymentMethodFilter?.issuer?.[0] 
      ,
      network: data?.discountCriteria?.paymentMethodFilter?.cardConfig?.network,
      issuerIdentificationNumber: data?.discountCriteria?.paymentMethodFilter?.cardConfig?.issuerIdentificationNumber,

      utmFilter : data?.discountCriteria?.utmFilter ?? [{}],
      paymentMethodsCriteria: data?.discountCriteria?.paymentMethodsCriteria?.length > 0 ? true : false,

      // discountFunctionality
      title: data?.discountFunctionality?.visibility?.title,
      description: data?.discountFunctionality?.visibility?.description,
      tnc: data?.discountFunctionality?.visibility?.tnc,
      visibility: data?.discountFunctionality?.visibility?.value,
      VisibilityActive: data?.discountFunctionality?.visibility?.active,
      showApplicable:data?.discountFunctionality?.visibility?.showApplicable,
      combination: data?.discountFunctionality?.combination?.values,
      discountLink: data?.discountFunctionality?.discountLink?.value,
      DiscountlinkActive: data?.discountFunctionality?.discountLink?.active,
      CombinationActive: data?.discountFunctionality?.combination?.active,
      caping: data?.discountFunctionality?.capping?.value,
      CapingActive: data?.discountFunctionality?.capping?.active,
      bulkType: data?.discountFunctionality?.bulkGenerate?.affix,
      affixValue: data?.discountFunctionality?.bulkGenerate?.affixValue,
      noOfCodes: data?.discountFunctionality?.bulkGenerate?.noOfCodes,
      bulkEmail: data?.discountFunctionality?.bulkGenerate.email,
      BulkGenerateActive: data?.discountFunctionality?.bulkGenerate?.active,
      paymentModes: data?.discountFunctionality?.paymentModes ?? [],
      PaymentModesActive: data?.discountFunctionality?.paymentModes?.length > 0,


      // Discount Validity
      startDate: data?.discountValidity?.startTime && moment(data?.discountValidity?.startTime),
      startTime: data?.discountValidity?.startTime && moment(data?.discountValidity?.startTime),
      endDate: data?.discountValidity?.endTime && moment(data?.discountValidity?.endTime),
      endTime: data?.discountValidity?.endTime && moment(data?.discountValidity?.endTime),

      // upselling config
      ...(data?.discountConfig?.aftersellPercentage && {
        aftersellPercentage: data?.discountConfig.aftersellPercentage,
        configDiscountType: 'percent',
        customerBuysListType: data?.discountConfig?.productType,
      }),
      ...(data?.discountConfig?.aftersellFlat && {
        flat: data?.discountConfig.aftersellFlat,
        configDiscountType: 'flat',
        customerBuysListType: data?.discountConfig?.productType,

      }),
      ...(data?.discountConfig?.discountPercentage && {
        discountPercentage:data?.discountConfig.discountPercentage,
        configDiscountType:'percent',
        freebieList:data?.discountConfig?.freebieList
      }),
      ...(data?.discountConfig?.discountFlat && {
        discountFlat:data?.discountConfig?.discountFlat,
        configDiscountType:'flat',
        freebieList:data?.discountConfig?.freebieList
      }),
      ...(data?.discountConfig?.freebieFlat &&{
        freebieFlat:data?.discountConfig?.freebieFlat,
        configDiscountType:'freebieFlat',
        freebieList:data?.discountConfig?.freebieList
      })

    });
  }
};


// [`customerGets${data?.discountConfig?.discountCond}`]:
//         data?.discountConfig?.customerGets?.[
//           data?.discountConfig?.discountCond
//       ],