import React from 'react'
import { Form, Checkbox } from "antd";

const ApplicableModes = (props: any) => {

  const HandleUpdate = () => {
    props.setCriteriaUpdate({
      ...props.criteriaUpdate,
      paymentMethodsCriteria : props.form.getFieldValue('paymentMethodsCriteria')
    })
  }
    return (
        <Form.Item
          name={"paymentMethodsCriteria"}
          valuePropName="checked"
        >
          <Checkbox 
            onChange={HandleUpdate}
            value="paymentMethodsCriteria"
          >
              Prepaid
          </Checkbox>
        </Form.Item>
    )
}

export default ApplicableModes