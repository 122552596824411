import axiosInstance from "HTTP/Http"

export const fetchPendingOrdersData = async ({ queryKey }: any) => {
    const [unusedArgument, {fromDate, toDate, pageNumber}] = queryKey;
    
    const response = await axiosInstance.get(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/order-pending?from=${fromDate}&to=${toDate}`,
        {
            params: {
                pageNumber: pageNumber >= 1 ? pageNumber-1 : pageNumber,
                pageSize: 20
            }
        }
    )

    return response?.data
}