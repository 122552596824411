import { CheckOutlined, CloseOutlined, ExclamationOutlined } from '@ant-design/icons';
import React, { useState } from 'react'
import { CustomPickrrButton, IconContainer, NormalText, PrimaryText } from './styles';
import { Radio, Space } from 'antd';
import { FlexContainer } from 'components/UIElements';
import { CrossIcon } from 'images/icons';
import { useMutation, useQueryClient } from 'react-query';
import { pendingOrderRefundInitiate } from './script';

const icons = {
    /* @ts-ignore */
    success: <CheckOutlined />,
    /* @ts-ignore */
    failed: <CloseOutlined />,
    /* @ts-ignore */
    warning: <ExclamationOutlined />,
};

const RefundPendingOrderModal = ({setShowRefundModal, rowDetails}:any) => {

    const [responseStatus, setResponseStatus] = useState<string>('');
    const [isResponseReceived, setIsResponseReceived] = useState<boolean>(false);
    const queryClient = useQueryClient();



    const mutation = useMutation(pendingOrderRefundInitiate, {
        onSuccess: (data) => {
            setResponseStatus(data?.success);
            setIsResponseReceived(true);
            queryClient.invalidateQueries('pendingOrdersData');
        }
    })

    function refundInitiateHandler () {
        if(rowDetails?.transaction?.amount >= 1){
            mutation.mutate(
                {
                    amount: rowDetails?.transaction?.amount,
                    order_id: rowDetails?.orderId,
                    cart_id: rowDetails?.cartToken
                }
            )

        }
    } 

    const ResponseSuccess = (
        <>
          <IconContainer color="#19870B">{icons["success"]}</IconContainer>
          <PrimaryText>Refund is successfully processed</PrimaryText>
          <NormalText>
            <span style={{ color: "#F0543C" }}>Note</span> : If refund is not
            reflected in the customer’s account after the date mentioned, the customer
            should reach out to their bank with the Refund
          </NormalText>
          <CustomPickrrButton
            color="transparent"
            background-color="transparent"
            onClick={()=>{setShowRefundModal(false)}}
          >
            Done
          </CustomPickrrButton>
        </>
    )

    const ResponseError = (
        <>
            <IconContainer color="#E22E2E">{icons["failed"]}</IconContainer>
            <PrimaryText>Refund Request Failed</PrimaryText>
            <NormalText>
            Your refund request for order ID 
            #{rowDetails?.orderId} is 
            <span style={{ color: "#F0543C" }}>failed</span>
            </NormalText>
            <NormalText>
            Please Contact at <a href="#"><u>fastrr@support.com</u></a> for help
          </NormalText>
          <CustomPickrrButton
            color="transparent"
            background-color="transparent"
            onClick={()=>{setShowRefundModal(false)}}
          >
            Try Again
          </CustomPickrrButton>
        </>
    )

    const Confirm = (
        <>
            <IconContainer color="#FC6A00">{icons["warning"]}</IconContainer>
            <PrimaryText>Please Confirm to Refund the amount</PrimaryText>
            <div style={{
                background: 'rgba(240, 246, 253, 0.6)',
                borderRadius: '8px',
                width: '328px',
                height: '115px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
            }}>

                Full Refund of
                &#8377;{
                    Number(rowDetails?.transaction?.amount)?.toFixed(2)}


            </div>
            <NormalText size={14} style={{ marginTop: 8, color: "#FF5B51" }}>
                Once refund is processed, it cannot be cancelled
            </NormalText>
            <NormalText size={12} style={{ marginTop: 0, color: "#0C3784" }}>
                Note: The refund amount must be greater than or equal &#8377;1
            </NormalText>

            <div>
                <FlexContainer style={{ gap: 12, marginTop: 8 }}>
                    <CustomPickrrButton
                        onClick={()=>{setShowRefundModal(false)}}
                        color="transparent"
                        background-color="transparent"
                    >
                        Cancel
                    </CustomPickrrButton>
                    <CustomPickrrButton
                        color="blue"
                        background-color="blue"
                    onClick = {refundInitiateHandler}
                    // loading = {mutation?.isLoading}
                    // disabled = {disableRefundButton}
                    >
                        Confirm
                    </CustomPickrrButton>
                </FlexContainer>
            </div>
        </>
    );


    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none">
            <div className="fixed inset-0 bg-black opacity-50" 
            onClick={() => { setShowRefundModal(false) }} 
            />
            <div className="flex flex-col z-50 bg-refundWhiteBg rounded-lg  p-4 h-fit-content w-[550px]">
                    <div
                        className='cursor-pointer h-5 mb-3 flex justify-end'
                        onClick={() => { setShowRefundModal(false) }}
                    >
                        <CrossIcon />
                    </div>

            <div className='flex flex-col relative items-center mb-3'>
                {!isResponseReceived ? Confirm : 
                responseStatus ? ResponseSuccess : ResponseError}
            </div>


            </div>

        </div>
    )
}

export default RefundPendingOrderModal