import React, { useContext, useEffect, useState } from "react";
import { Container, Label, StatusContainer, StatusContainerActive, StatusContainerInactive, TableDataContainer } from "./style";
import { CustomForm, FlexContainer, PickrrButton } from "components/UIElements";
import { Checkbox, Col, Form, Input, Row, Select, Space, Spin, Switch, Table, notification } from "antd";
import AddPaymentGatewayModal from "./AddPaymentGatewayModal";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { FetchPaymentConfig, UpdatePaymentGateway } from "../script";
import { DataContext } from "context/DataProvider";
import { useSearchParams } from "react-router-dom";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axiosInstance from '../../../../HTTP/Http';
import { PaymentGatewayEnum } from "context/Enums";

const ManageUPIPaymentNew = ({loading, setLoading, success, setSuccess, cashfreeLoading, setCashfreeLoading, cashfreeSuccess, setCashfreeSuccess}) => {
    const [form] = Form.useForm();
    const antIcon = <LoadingOutlined style={{ fontSize: 15, color:'#FFF' }} spin />;
    const [searchParams, setSearchParams] = useSearchParams();
    const [newPaymentGatewayModalVisible, setNewPaymentGatewayModalVisible] = useState(false)
    const {sellerId} = useContext(DataContext) 
    const [priorityList, setPriorityList] = useState({})
    const [modalGatewayData ,setModalGatewayData] = useState({})
    const queryClient = useQueryClient();
    const [globalPaymentConfigData,setGlobalPaymentConfigData] = useState([])
    const [viewOnly, setViewOnly] = useState(false)
    const [updateState,setUpdateState]= useState(false)
    // const [loading , setLoading]= useState(false)
    // const [success , setSuccess] =useState(false)
    const [razorpayData, setRazorpayData] = useState({});
    const [availableGateways,setAvailableGateways] = useState([]);
    const [cashfreeData, setCashfreeData] = useState([]);

    const mutation = useMutation(UpdatePaymentGateway, {
      onMutate: (variables) => {
        return { id: 1 };
      },
      onError: (error, variables, context) => {
        notification.error({ message: 'Something went wrong.' });
      },
      onSuccess: (message, variables, data) => {
        queryClient.invalidateQueries('userDetails');
        setUpdateState((prev)=>!prev)
        notification.success({ message: 'Updated Payment details.' });
      },
    });

    useEffect(async() => {
        const isCashfree = searchParams.get('merchant_id')?.split("_")?.[0]?.includes('CF');

      if (searchParams.get("code") && !isCashfree && searchParams.get("state")) {
        setLoading(true);
        const code = searchParams.get("code")
        const state = (searchParams.get("state"))
        try{
        const response =await axiosInstance.get(`${process.env.REACT_APP_PAYMENT}/api/ve1/payment-aggregator/seller/config/generate-token?code=${code}&state=${state}`)
        if(response?.data?.error){
          notification.error({ message: 'Something went wrong' });
          setLoading(false)
          searchParams.delete('state')
          searchParams.delete('code')
          setSearchParams(searchParams)
        }else{
          setSuccess(true)
          setLoading(false)
          notification.success({message : 'Authorization successful'})
          searchParams.delete('state')
          searchParams.delete('code')
          setSearchParams(searchParams)
          queryClient.invalidateQueries(`userDetails`)
        }
        }catch (err) {
          notification.error({ message: err.message });
          setLoading(false)
          searchParams.delete('state')
          searchParams.delete('code')
          setSearchParams(searchParams)
        }
      }
      else if(searchParams.get("error")){
        notification.error({ message: searchParams.get("error_description") });
        searchParams.delete('state')
        searchParams.delete('error')
        searchParams.delete('error_description')
        searchParams.delete('hint')
        searchParams.delete('message')
        setSearchParams(searchParams)
      }
  }, [searchParams]);

    const { data : PaymentData, isLoading, isSuccess } = useQuery(
      ['userDetails', {sellerId}],
      FetchPaymentConfig
    );

    useEffect(()=>{
      if(PaymentData){
        setGlobalPaymentConfigData(PaymentData?.globalPaymentConfig)
        setAvailableGateways( PaymentData?.globalPaymentConfig?.map((paymentConfigData)=>{return(paymentConfigData?.payment_gateway?.trim()?.toUpperCase())}) ?? [])
      }else{
        setGlobalPaymentConfigData([])
        setAvailableGateways([])
      }
      if(PaymentData?.sellerPaymentConfiguration && Object.keys(PaymentData?.sellerPaymentConfiguration)?.length > 0){
        form.setFieldsValue({
          is_cod_at_top : PaymentData?.sellerPaymentConfiguration?.is_cod_at_top ?? false,
          display_count : PaymentData?.sellerPaymentConfiguration?.other_payment_methods_display_count ?? ''
        })
      }
    },[PaymentData])

    useEffect(()=>{
      let paymentModePriority = {}
      PaymentData?.sellerPaymentConfiguration?.payment_methods?.map((paymentMethods)=>{
        paymentModePriority = {
          ...paymentModePriority,
          [paymentMethods?.payment_mode] : [SecondaryPriority(paymentMethods?.gateway_priority),PrimaryPriority(paymentMethods?.gateway_priority)]
        }
      })
      setPriorityList(paymentModePriority)
    },[PaymentData, updateState])

    const columns = [
        {
          title: 'Gateway',
          dataIndex: 'gateway',
          editable: false,
          render:(data)=>{
            return PaymentGatewayEnum[data?.toUpperCase()] ? PaymentGatewayEnum[data?.toUpperCase()]: data
          }
        },
        {
          title: 'Status',
          dataIndex: 'active',
          editable: false,
          render : (data) => {
            return(
              <TableDataContainer style={{ display:'flex'}}>
                {/* {data ? 
                  <StatusContainerActive> 
                    ACTIVE
                  </StatusContainerActive>
                  :
                  <StatusContainerInactive>
                    INACTIVE
                  </StatusContainerInactive>
                } */}
                {
                  data ? 'ACTIVE' : 'INACTIVE'
                }
              </TableDataContainer>
            )
          }
        },
        {
          title: 'Payment Modes',
          dataIndex: 'paymentMode',
          editable: true,
          render: (data,record,index) => {
            return(
                <TableDataContainer style={{ display:'flex'}}>
                    {data?.map((paymentModeDetail)=>{
                      return(
                        <span>
                          {paymentModeDetail?.mode?.toUpperCase()==='POTLEE'?'SETTLE':paymentModeDetail?.mode}
                        </span>
                      )
                    })}
                </TableDataContainer>
            )
          },  
        },
        {
        title: 'View Details',
        dataIndex: 'fast',
        editable: true,
        render: (data,record,index) => {
          return(
              <TableDataContainer style={{ display:'flex'}}>
                  <span 
                  onClick={()=>{
                    if(availableGateways.includes(record?.gateway?.trim()?.toUpperCase()==='POTLEE'?'SETTLE':record?.gateway?.trim()?.toUpperCase())){
                      setViewOnly(true)
                      setNewPaymentGatewayModalVisible(true)
                      setModalGatewayData(record)
                    }
                    
                  }}
                  style={{textDecoration : 'underline', cursor :availableGateways.includes(record?.gateway?.trim()?.toUpperCase()==='POTLEE'?'SETTLE':record?.gateway?.trim()?.toUpperCase()) ?  'pointer' : 'not-allowed'}}
                  >
                    View
                  </span>
              </TableDataContainer>
          )
        },  
        },
        {
        title: 'Action',
        dataIndex: 'no_rush',
        editable: true,
        render: (data,record,index) => {
          return(
              <TableDataContainer style={{ display:'flex'}}>
                  <span 
                  onClick={()=>{
                    if(availableGateways.includes(record?.gateway?.trim()?.toUpperCase()==='POTLEE'?'SETTLE':record?.gateway?.trim()?.toUpperCase())){
                      setViewOnly(false)
                      setNewPaymentGatewayModalVisible(true)
                      setModalGatewayData(record)
                    }
                  }}
                  style={{textDecoration : 'underline', cursor :availableGateways.includes(record?.gateway?.trim()?.toUpperCase()==='POTLEE'?'SETTLE':record?.gateway?.trim()?.toUpperCase()) ?  'pointer' : 'not-allowed'}}>
                    Edit
                  </span>
              </TableDataContainer>
          )
        }, 
        },
    ]

    const handleAddPaymentGateway = () => {
      setNewPaymentGatewayModalVisible(true)
      setRazorpayData(PaymentData?.sellerPaymentConfiguration?.payment_gateways?.filter((gatewaydata)=>gatewaydata?.gateway?.toUpperCase() === "RAZORPAY")?.[0] ?? {})
      setCashfreeData(PaymentData?.sellerPaymentConfiguration?.payment_gateways?.filter((gatewaydata)=>gatewaydata?.gateway?.toUpperCase() === "CASHFREE")?.[0] ?? {})
    }

    const PrimaryPriority = (paymentModeArray) => {
      const maxPriorityValue =  Math.max(...paymentModeArray.map(o => o.priority))
      const primaryPaymentPriority = paymentModeArray.find((element) => element.priority === maxPriorityValue)
      return primaryPaymentPriority?.payment_gateway
    }

    const SecondaryPriority = (paymentModeArray) => {
      const maxPriorityValue =  Math.max(...paymentModeArray.map(o => o.priority))
      const remainingPaymentModeArray = paymentModeArray.filter((element) => element.priority !== maxPriorityValue)
      return PrimaryPriority(remainingPaymentModeArray)
    }

    const PaymentModecolumns = [
        {
          title: 'Payment Mode',
          dataIndex: 'payment_mode',
          editable: false,
          render: (data)=>{
            return  PaymentGatewayEnum[data?.toUpperCase()] ? PaymentGatewayEnum[data?.toUpperCase()]: data
          }
        },
        {
          title: 'Primary Gateways',
          dataIndex: 'gateway_priority',
          editable: true,
          render: (data,record,index) => {
            return(
                <TableDataContainer style={{ display:'flex'}}>
                    <Select 
                    key={priorityList[record?.payment_mode?.toUpperCase()]?.length > 1 ? priorityList[record?.payment_mode?.toUpperCase()][1] : '' }
                    value={priorityList[record?.payment_mode?.toUpperCase()]?.length > 1 ? priorityList[record?.payment_mode?.toUpperCase()][1] : '' }
                    options={data?.map((paymentmodes)=>{
                      return({
                        value : paymentmodes?.payment_gateway,
                        label : PaymentGatewayEnum[paymentmodes?.payment_gateway?.toUpperCase()] ? PaymentGatewayEnum[paymentmodes?.payment_gateway?.toUpperCase()]: paymentmodes?.payment_gateway?.toUpperCase()
                        ,
                      })})}
                      style={{width : '80%'}}
                    onChange={(value)=>{
                      setPriorityList((prev)=>({
                        ...prev,
                        [record?.payment_mode] : [prev[record?.payment_mode]?.[0] ?? null,value]
                      }))
                    }}
                    placeholder = "SELECT GATEWAY"
                    />
                </TableDataContainer>
            )
          }, 
        },
        {
          title: 'Secondary Gateway',
          dataIndex: 'gateway_priority',
          editable: true,
          render: (data,record,index) => {
            return(
                <TableDataContainer style={{ display:'flex'}}>
                    <Select 
                    key={priorityList[record?.payment_mode?.toUpperCase()]?.length > 0 ? priorityList[record?.payment_mode?.toUpperCase()][0] : '' }
                    defaultValue={priorityList[record?.payment_mode?.toUpperCase()]?.length > 0 ? priorityList[record?.payment_mode?.toUpperCase()][0] : '' }
                    options={data?.map((paymentmodes)=>{
                      return({
                        value : paymentmodes?.payment_gateway,
                        label : PaymentGatewayEnum[paymentmodes?.payment_gateway?.toUpperCase()] ? PaymentGatewayEnum[paymentmodes?.payment_gateway?.toUpperCase()]: paymentmodes?.payment_gateway?.toUpperCase()
                      })})}
                    style={{width : '80%'}}
                    onChange={(value)=>{
                      setPriorityList((prev)=>({
                        ...prev,
                        [record?.payment_mode] : [value, prev[record?.payment_mode]?.[1] ?? null]
                      }))
                    }}
                    placeholder = "SELECT GATEWAY"
                    />
                </TableDataContainer>
            )
          }, 
        },
    ] 

    const handleUpdatePaymentModePriority = async() => {
      try{
        const reqPayload = {
          seller_id : sellerId,
          payment_mode_priority_data : priorityList          
        }
        const response = await axiosInstance.post(`${process.env.REACT_APP_REPORTING}/api/ve1/dashboard-service/set-payment-mode-priority`,
        reqPayload)
        if(response?.data?.success){
          queryClient.invalidateQueries('userDetails');
          setUpdateState((prev)=>!prev)
          notification.success({ message: 'Updated Payment details.' });
        }
      }catch(error){
        notification.error({message:error?.response?.data?.message})
      }
    }
    
    const submitForm = async()=>{
      // setSavingSettings(true)
      let postData = { ...form.getFieldValue() };
      const reqPayload = {
        "seller_id": sellerId,
        "is_cod_at_top":postData?.is_cod_at_top,
        'other_payment_methods_display_count' : postData?.display_count,
      }
      mutation.mutate(reqPayload)
      
    }

    return(
        <Container>
          {PaymentData?.sellerPaymentConfiguration && Object.keys(PaymentData?.sellerPaymentConfiguration)?.length > 0 &&
            <CustomForm layout="vertical" form={form} onFinish={submitForm}>
            <Row style={{marginBottom : '16px'}}>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Form.Item
                label="COD at top"
                name="is_cod_at_top"
                rules={[
                  {
                    required: true,
                    message: 'COD placement is required',
                  },
                ]}
                valuePropName="checked" 
              >
              <Switch
              loading = {mutation.isLoading}
              />
              </Form.Item>
              </Col>  
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Form.Item
                label="Other payment method display count"
                name="display_count"
                rules={[
                  {
                    required: true,
                    message: 'Display count is required',
                  },
                ]}
              >
              <Input
              disabled = {mutation.isLoading}
              min={0}
              type="number"
              />
              </Form.Item>
              </Col>   
              <Space>
                <PickrrButton htmlType="submit" color="checkout-blue" disabled={mutation.isLoading} style={{minWidth:"150px"}} >
                  { mutation.isLoading ? <Spin indicator={antIcon} /> : "Save" }
                </PickrrButton>
              </Space>        
            </Row>
            </CustomForm>
          }
            <FlexContainer style={{justifyContent : 'space-between',marginBottom : '10px'}}>
                <span style={{color : '#000000',fontSize : '16px',fontWeight : 700, lineHeight : '32px'}}>
                Payment Gateways
                </span>
                <PickrrButton 
                color="checkout-blue"
                onClick={()=>handleAddPaymentGateway()}
                >
                Add New Gateway 
                </PickrrButton>
            </FlexContainer>
            <Table
            columns={columns}
            dataSource={PaymentData?.sellerPaymentConfiguration?.payment_gateways}
            style={{marginBottom : '30px'}}
            >

            </Table>
            <FlexContainer style={{justifyContent : 'space-between',marginBottom : '10px'}}>
                <span style={{color : '#000000',fontSize : '16px',fontWeight : 700, lineHeight : '32px'}}>
                Payment Modes
                </span>
                <PickrrButton color="checkout-blue" onClick={()=>{
                  handleUpdatePaymentModePriority()
                }}
                disabled = {(PaymentData?.sellerPaymentConfiguration?.payment_methods?.length < 1) || (!PaymentData?.sellerPaymentConfiguration?.payment_methods)}
                >
                   Save
                </PickrrButton>
            </FlexContainer>
            <Table
            key={PaymentData?.sellerPaymentConfiguration?.payment_methods}
            columns={PaymentModecolumns}
            style={{marginBottom : '30px'}}
            dataSource={PaymentData?.sellerPaymentConfiguration?.payment_methods}
            >

            </Table>
            

            {newPaymentGatewayModalVisible &&
                <AddPaymentGatewayModal
                isVisible = {newPaymentGatewayModalVisible}
                setIsVisible = {setNewPaymentGatewayModalVisible}
                gatewayData={modalGatewayData}
                setGatewayData = {setModalGatewayData}
                globalPaymentConfigData = {globalPaymentConfigData}
                sellerId = {sellerId}
                viewOnly={viewOnly}
                setViewOnly = {setViewOnly}
                setUpdateState = {setUpdateState}
                loading= {loading}  
                setLoading = {setLoading} 
                success= {success}  
                setSuccess = {setSuccess}
                razorpayData = {razorpayData}
                cashfreeData = {cashfreeData}
                cashfreeLoading = {cashfreeLoading}
                setCashfreeLoading = {setCashfreeLoading}
                cashfreeSuccess = {cashfreeSuccess}
                setCashfreeSuccess = {setCashfreeSuccess}
                />
            }


        </Container>
    )

}

export default ManageUPIPaymentNew