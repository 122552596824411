import { Checkbox, Col, Form, Input, Row, Switch, notification } from "antd";
import React, { useEffect, useState } from "react";
import { CancelButton, GatewayFormContainer, ModalSubHeading } from "./style";
import { CustomForm, FlexColCenterContainer, PickrrButton } from "components/UIElements";
import { useMutation, useQueryClient } from "react-query";
import { UpdatePaymentGateway } from "../script";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const CheckboxGroup = Checkbox.Group;
const RazorpayGatewayForm = ({gatewayData,gatewayConfig,sellerId,setIsVisible,viewOnly, setViewOnly,setGatewayData , setSelectedGatewayConfig ,setUpdateState,loading , setLoading,success , setSuccess}) => {

    const [checkedList, setCheckedList] = useState([]);
    const [merchantId, setMerchanId] = useState(false);
    const [merchantKey, setMerchanKey] = useState(false);
    const [paymentModeList,setPaymentmodeList] = useState([])
    const queryClient = useQueryClient();

    const handleAuthorizeWithRazorPay = async() => {
        setLoading(true)
        try{
          const response = await axios.get(
            `${process.env.REACT_APP_PAYMENT}/api/ve1/payment-aggregator/seller/config/auth/${sellerId}?gateway=RAZORPAY`
          );
          const {clientId , redirectUri, responseType, scope, state} = response?.data?.gatewayAuthResponse
          window.open(`https://auth.razorpay.com/authorize?client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`,'_self')
        }catch (err) {
          notification.error({ message: err.message });
          setLoading(false)
          notification()
        }
      }


    useEffect(()=>{
        if(gatewayData?.pgDetail){
            form.setFieldsValue({
                razor_payment_details : {
                    merchant_id : gatewayData?.pgDetail?.merchant_id ?? '',
                    merchant_client_id : gatewayData?.pgDetail?.merchant_client_id ?? '',
                    merchant_secret : gatewayData?.pgDetail?.merchant_secret ?? '',
                    isActive : gatewayData?.active 
                }
            })
        }
        if(gatewayData?.paymentMode){
            const SelectedPaymentModes =  gatewayData?.paymentMode?.map((paymentModesDetails)=>{
                return paymentModesDetails?.mode
            })
            setCheckedList(SelectedPaymentModes)
        }
        if(!gatewayData?.pgDetail){
        form.setFieldsValue({
            razor_payment_details : {
                isActive : true
            }
        })
        }
    },[gatewayData])

    const mutation = useMutation(UpdatePaymentGateway, {
      onMutate: (variables) => {
        return { id: 1 };
      },
      onError: (error, variables, context) => {
        notification.error({ message: 'Something went wrong.' });
      },
      onSuccess: (message, variables, data) => {
        queryClient.invalidateQueries('userDetails');
        setUpdateState((prev)=>!prev)
        notification.success({ message: 'Updated Payment details.' });
        setGatewayData({})
        setSelectedGatewayConfig({})
        setViewOnly(false)
        setIsVisible(false)
      },
    });

    useEffect(()=>{
        setPaymentmodeList(
            Object.keys(gatewayConfig?.payment_mode)?.map(function (key, index) {
                return key
            })
        )
    },[gatewayConfig])

    const onChange = (list) => {
        setCheckedList(list);
      };

    const [form] = Form.useForm();

    const submitForm = () => {
        let uncheckedList = paymentModeList?.filter( function( el ) {
            return checkedList.indexOf( el ) < 0;
          } );
        let postData = { ...form.getFieldsValue() };
        let gatewayKeys = {
            is_active:`${postData.razor_payment_details?.isActive}`,
              }
        const reqPayload = {
            "seller_id":sellerId,
            "payment_platform":"Razorpay",
            "gateway_keys":JSON.stringify(gatewayKeys),
            "enabled_payment_methods":postData.razor_payment_details?.isActive ? checkedList : [],
            "disabled_payment_methods":postData.razor_payment_details?.isActive ?uncheckedList : paymentModeList
        }
        mutation.mutate(reqPayload)
    
    }

    return(
        <GatewayFormContainer>
        <CustomForm layout="vertical" form={form} onFinish={submitForm}>
        <Row gutter={[20,0]}>
        <Col span={24} style={{display : 'flex', justifyContent : 'flex-end'}}>
          <Form.Item
            name={['razor_payment_details', 'isActive']}
            rules={[
              { 
                required: true, 
                message: "Status for payment gateway is mandatory",
            }
            ]}
          >
            <Switch
            defaultChecked={gatewayData?.active ?? true}
            style={{marginRight : '10px'}} 
            checkedChildren="Active" unCheckedChildren="Inactive"
            disabled={viewOnly}
            />
          </Form.Item>
        </Col>
        <FlexColCenterContainer style={{width : '100%', marginBottom : '20px'}}>
            {(success || (gatewayData?.pgDetail && !gatewayData?.pgDetail?.access_token_required)) ?
              <PickrrButton
              disabled
              >
                Authorized
              </PickrrButton>
              :
              <PickrrButton
              onClick={handleAuthorizeWithRazorPay} 
              color="checkout-blue"
              loading = {loading}
              >
                Authorize With RazorPay
              </PickrrButton>
            } 
          </FlexColCenterContainer>
        </Row>
        <ModalSubHeading>
            Payment Modes
        </ModalSubHeading>
        <Row gutter={[5,5]} style={{marginBottom : '12px'}}>
        <CheckboxGroup disabled={viewOnly || !(success || (gatewayData?.pgDetail && !gatewayData?.pgDetail?.access_token_required))} options={paymentModeList} value={checkedList} onChange={onChange} />
        </Row>
        {!viewOnly &&
        <>
        <CancelButton
        disabled={viewOnly}
        onClick={()=>{
            setGatewayData({})
            setIsVisible(false)
            setSelectedGatewayConfig({})
            setViewOnly(false)
        }}
        >
            Discard
        </CancelButton>
        <PickrrButton
        htmlType="submit"
        style={{width : '100px', border : '0.742px',textTransform : 'none'}}
        color="checkout-blue"
        disabled={viewOnly || !(success || (gatewayData?.pgDetail && !gatewayData?.pgDetail?.access_token_required))}
        >
            Save
        </PickrrButton>
        </>
        }
        </CustomForm>
        </GatewayFormContainer>
    )


}

export default RazorpayGatewayForm;