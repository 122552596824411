
import {sideBox} from './data';

export const pathObject: sideBox = {
    '/home':['Home'],
    '/fastrrlogin':['Fastrr Login'],
    '/': ['Dashboard'],
    '/dashboard': ['Dashboard'],
    '/settings/general-settings': ['Settings'],
    '/settings/discount': ['Settings'],
    '/settings/shipping': ['Settings'],
    '/settings/payments': ['Settings'],
    '/settings/inventory': ['Settings'],
    '/settings/manage-channel/shopify': ['Settings'],
    '/settings/tracking-info': ['Settings'],
    '/settings/webhooks': ['Settings'],
    '/payment': ['Payment'],
    '/orders': ['Orders', 'Orders'],
    '/pending-orders': ['Orders', 'Pending Orders'],
    '/finance': ['Finance', 'Customer Finance'],
    '/refunds': ['Finance', 'Refunds'],
    '/wallet': ['Finance', 'Wallet'],
    '/settlementhistory': ['Finance', 'Settlement History'],
    '/abandoned-cart': ['Abandoned Carts'], 
    '/user-management': ['User Management']
}